import React, { useEffect } from 'react';
import WebinarPage from '../components/webinar-page';
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const WebinarIPStrategy = () => {
  //
  const webinarUrl = "https://webinarkit.com/webinar/registration/oneclick/67bc32f9e2aace524737a7a4?date=jit_15";
  const title = "Small Biz IP Growth Hacks 2025: Grow Using Your Very Own Intellectual Property";
  const canonical = "/webinar-ip-strategy-for-small-business-growth";
  const introAuthor = "Bao Tran is an intellectual property strategist and advisor at PatentPC."
  const lessons = [
    "How IP can be a tool for small business sustainability and growth.",
    "Creating a strong IP portfolio for competitive advantage.",
    "Best practices in IP management for small enterprises.",
    "Using IP to enhance market presence and revenue streams."
  ]
  const btnTxt = "Watch Webinar Now";
  useEffect(() => {
    document?.body.classList.add('no-home');
    //
  }, []);

  return (
    <>
    <WebinarPage webinarUrl={webinarUrl} introAuthor={introAuthor} title={title} canonical={canonical} lessons={lessons} btnTxt={btnTxt}></WebinarPage>
    </>
  )
};

export default WebinarIPStrategy;